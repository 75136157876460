import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Twitter, Telegram, LinkedIn } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "tss-react/mui";
import { Divider, useMediaQuery } from "@mui/material";

export default function ComunityButton({ pad, marg }) {
  const { classes } = useStyles();
  const smallPoint = useMediaQuery("(max-width: 750px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = {
    Twitter: {
      icon: <Twitter style={{ color: "#65A9DE" }} />,
      text: "Twitter",
      link: "https://twitter.com/GlitterFund_",
    },
    LinkedIn: {
      icon: <LinkedIn style={{ color: "#65A9DE" }} />,
      text: "LinkedIn",
      link: "https://www.linkedin.com/company/glitter-fund",
    },
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      padding: smallPoint ? "4px 5px" : "4px 30px",
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "0px 0%",
        color: "#65A9DE",
        "& .MuiMenuItem-root": {
          height: "40px",
          //   padding: "0px 0px",
          margin: "0px 0px",
        },
      },
      background: "linear-gradient(180deg, #84F3F4 -1.84%, #65A9DE 101.88%)",
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <>
      <Button
        className={classes.activeBtn}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          padding: pad ?? "7px 11%",
          margin: marg ?? 0,
        }}
      >
        Community
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        sx={{
          padding: pad ?? "7px 11%",
          margin: marg ?? 0,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        smallPoint={smallPoint}
      >
        {Object.keys(menuItems).map((x, i) => (
          <>
            <MenuItem
              key={i}
              onClick={handleClose}
              disableRipple
              className={classes.menuItemClass}
            >
              {menuItems[x].icon}
              <a href={menuItems[x].link} target="_blank" rel="noreferrer">
                {menuItems[x].text}
              </a>
            </MenuItem>
            {i !== Object.keys(menuItems).length - 1 && (
              <Divider sx={{ color: "#65A9DE" }} />
            )}
          </>
        ))}
      </StyledMenu>
    </>
  );
}

const useStyles = makeStyles()((theme) => ({
  activeBtn: {
    position: "relative",
    fontFamily: "ProximaNova",
    fontSize: "15px",
    color: "#fff",
    fontWeight: "700",
    background: "linear-gradient(180deg, #84F3F4 -1.84%, #65A9DE 101.88%)",
    borderRadius: "50px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: "0 !important",
      fontSize: "10px",
    },
  },
  menuItemClass: {
    fontFamily: "ProximaNova",
    "&:hover": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      height: "20px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
}));
