import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import "./App.css";
import Root from "./Root";

let darkTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: "none !important",
          backdropFilter: "blur(20px)",
        },
      },
    },
  },
  palette: {
    success: {
      main: "#5AC491",
    },
    warning: {
      main: "#E8C342",
    },
    error: {
      main: "#E84242",
    },
  },
});

const _darkTheme = responsiveFontSizes(darkTheme);
const cache = createCache({
  key: "css",
  prepend: true,
});

function App() {
  return (
    <CacheProvider value={cache}>
      <CssBaseline />
      <ThemeProvider theme={_darkTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Root />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
