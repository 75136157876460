import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme) => ({
  container: {
    marginRight: "8%",
    marginLeft: "8%",
    padding: "2%",
    backgroundColor:'rgba(255,255,255,0.8)',
    borderRadius:8,
  },
  banner: {
    height: "100vh",
    paddingTop: "200px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "2%",
    },
  },
  bannerText: {
    fontFamily: "ProximaNova",
    fontWeight: 700,
    fontSize: "60px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "ProximaNova",
      paddingTop: "30%",
      fontSize: "50px",
    },
  },
  bannerText1: {
    color: "var(--clr-pink)",
  },
  bannerText2: {
    color: "var(--clr-purple)",
  },
  bannerText3: {
    color: "var(--clr-deep-blue)",
    fontSize: "35px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },
  headerImage:{
   width:'100%',
   height: 'auto',
   overflow:'hidden',
   borderRadius:8
  }
}));

export default styles;
