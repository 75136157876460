import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme) => ({
  rootContainer: {
    flex: 1,
    display: "flex",
    height: "100%",
    minHeight: "100vh",
    background: "linear-gradient(96.34deg, #EEE2FF 0%, #FFF3FA 100%)",
  },
  ballsLayer: {
    flex: 1,
    height: "100%",
    minHeight: "100vh",
    backgroundPosition:
      "0% 55%,0% 100%,100% 0%,93% 46%,93.4% 87%,16% 0%, 10% 105%",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundSize: "16%,11.5%,44%,10.5%,8.5%, 100%, 100%",
    backgroundOrigin: "content-box",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "20%,19.5%,47%,15.5%,12.5%,100%,100%",
      backgroundPosition:
        "0% 60%,0% 100%,100% 0%,96% 39%,93.4% 93%,92% -11px,16% 31%,8% 15%",
    },
  },
}));

export default styles;
