import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
  return {
    contentContianer: {
      height: "170px",
      padding: "1% 8%",
      [theme.breakpoints.down("sm")]: {
        padding: "5% 8%",
        flexDirection: "row",
        justifyContent: "space-between !important",
      },
    },
    logoDiv: {
      width: "auto",
    },
    logoImg: {
      height: "96px",
      width: "224px",
      verticalAlign: "middle",
      [theme.breakpoints.down("sm")]: {
        height: "60px",
        width: "133px",
      },
    },
  };
});

export default useStyles;
