import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import GlossyStrip from "../../comp-reusable/GlossyStrip";
import PartnerTile from "../../comp-reusable/PartnerTile";

import glitterLogo from "../../assets/imgs/glitter-logo.svg";
import xglidaoLogo from "../../assets/imgs/xgli-dao-logo.svg";
import rainbowLogo from "../../assets/imgs/rainbow-logo.svg";
import PrimaryButton from "../../comp-reusable/PrimaryButton";
import styles from "./HomeStyles";
import ContactUs from "./components/ContactUs";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const { classes, cx } = styles();
  const smallPoint = useMediaQuery("(max-width: 750px)");
  const navigate = useNavigate();


  return (
    <Grid className={classes.homeRootContainer}>
      <Grid className={classes.container}>
        <Grid className={classes.banner}>
          <Typography className={classes.bannerText}>
            <p className={classes.firstLineText}>
              <span className={classes.bannerText1}>The Glitter Finance</span>
              <span className={classes.bannerText2}>&nbsp; Foundation</span>
            </p>
            <span className={classes.bannerText3}>
              Building an ease-of-use economy
            </span>
          </Typography>
          <br />
          <PrimaryButton
            text="More info"
            marg="3px 3px"
            pad={smallPoint ? "5px 5%" : "13px 7%"}
            href="#aboutus"
            onClick={() =>  navigate("/#aboutus")}
          />
          &nbsp;
          {smallPoint && (
            <>
              <PrimaryButton
                text="Contact us "
                marg="3px 3px"
                pad={"5px 5%"}
                href="#contactus"
                onClick={() =>  navigate("/#contactus")}
              />
            </>
          )}
           &nbsp;
          {smallPoint && (
            <>
              <PrimaryButton
                text="Blog"
                marg="3px 3px"
                pad={"5px 5%"}
                href=""
                onClick={() =>  navigate("/blog")}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.about}>
        <Typography
          className={cx(classes.bannerText, classes.sectionHeading)}
          sx={{
            marginTop: smallPoint ? "-25% !important" : "0% !important",
            paddingTop: smallPoint ? "0% !important" : "",
          }}
          id="aboutus"
        >
          About
        </Typography>
        <GlossyStrip>
          <Grid
            className="flex-row-center"
            sx={{
              padding: smallPoint ? "10% 20%" : "3% 20%",
            }}
          >
            <Typography className={classes.aboutText}>
              Called The{" "}
              <span style={{ fontWeight: "bold" }}> Glitter Fund</span>, the
              focus of the Glitter Finance Foundation is to steward the
              development of the{" "}
              <span className={classes.boldnames}> Glitter Finance</span> and
              the <span className={classes.boldnames}> Rainbow Network</span>{" "}
              ecosystem. Future development is the establishment of a grant
              program for protocols that are interested in building on the
              Glitter Finance and the Rainbow Network. The Glitter Fund will
              also work to establish a robust developer relations program, to
              support the burgeoning ecosystem. The foundation will actively
              work with the recently established{" "}
              <span className={classes.boldnames}> XGLI DAO</span>, to
              collectively govern the XGLI token and other tokens created within
              the ecosystem.
            </Typography>
          </Grid>
        </GlossyStrip>
      </Grid>
      <Grid className={classes.contactUs}>
        <Typography
          className={cx(classes.bannerText, classes.sectionHeading)}
          sx={{ marginBottom: "0% !important" }}
          id="contactus"
        >
          Contact Us
        </Typography>
        <Typography
          className={classes.aboutText}
          sx={{ marginBottom: smallPoint ? "6%" : "1% !important" }}
        >
          Contact us and we will answer all your questions
        </Typography>
        <ContactUs />
      </Grid>
      <Grid className={classes.partners} id="partners">
        <Typography className={cx(classes.bannerText, classes.sectionHeading)}>
          Partners
        </Typography>
        <GlossyStrip>
          <Grid className={classes.partnersSection}>
            <PartnerTile pad={smallPoint ? "0px 6%" : "1% 6%"} marg="0px 5px">
              <img
                className={classes.partnersLogos}
                src={glitterLogo}
                alt="partner"
              />
            </PartnerTile>
            <PartnerTile pad={smallPoint ? "0px 6%" : "1% 6%"} marg="0px 5px">
              <img
                className={classes.partnersLogos}
                src={rainbowLogo}
                alt="partner"
                style={{
                  width: smallPoint ? "50px" : "150px",
                }}
              />
            </PartnerTile>
            <PartnerTile pad={smallPoint ? "0px 6%" : "1% 6%"} marg="0px 5px">
              <img
                className={classes.partnersLogos}
                src={xglidaoLogo}
                alt="partner"
              />
            </PartnerTile>
          </Grid>
        </GlossyStrip>
      </Grid>
    </Grid>
  );
}
