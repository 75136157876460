import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

export default function PartnerTile({ children, pad, marg }) {
  const { classes } = useStyles();
  return (
    <Box
      className={classes.tileroot}
      sx={{
        padding: pad ?? "7px 15px",
        margin: marg ?? "0px 5px",
      }}
    >
      {children}
    </Box>
  );
}

const useStyles = makeStyles()((theme) => ({
  tileroot: {
    fontFamily: "ProximaNova",
    border: "2px solid #FFFFFF",
    WebKitBackdropFilter: "blur(12.5px)",
    MozBackdropFilter: "blur(12.5px)",
    borderRadius: "100px",
  },
}));
