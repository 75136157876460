import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import styles from "./BlogStyles";
import BlogHeader from "../../assets/imgs/grantprogram.jpg";
export default function UnveilingGitterFund() {
  const { classes, cx } = styles();
  const smallPoint = useMediaQuery("(max-width: 750px)");
  return (
    <Grid className={classes.homeRootContainer}>
      <Grid className={classes.container}>
        <div>
          <img
            src={BlogHeader}
            alt="Introducing the Glitter Finance Foundation"
            className={classes.headerImage}
          />
          {/* <h1 className={classes.bannerText2}>
            Introducing the Glitter Finance Foundation
          </h1> */}
          <p>
            After recently unveiling the Glitter Finance Foundation, we are
            thrilled to introduce the Glitter Fund Grant Program, an initiative
            designed to expedite innovation and growth within the <a href="https://www.glitterfinance.org/">Glitter
            Finance</a> and <a href="https://www.rainbownetwork.io/">Rainbow Network</a> ecosystems. This program is one of the
            core operations of the Foundation and serves our broader mandate of
            supporting decentralisation and providing seamless access to
            liquidity across various blockchains.
          </p>
          <h3 className={classes.bannerText1}>Grant Program Details</h3>
          <p>
            The Glitter Fund Grant Program aims to ignite creativity and drive
            adoption of both Glitter and Rainbow by offering financial support
            to groundbreaking projects. In addition, Glitter Finance—in
            collaboration with the Glitter Fund and XGLI DAO—will offer
            technical expertise and implementation support to grant awardees,
            ensuring their projects come to fruition.
          </p>
          <p>
            To foster a collaborative environment and strengthen the network,
            Glitter Finance is also focusing on nurturing developer relations
            within the ecosystem. Projects interested in collaboration, looking
            for introductions to our ecosystem, or looking to become ecosystem
            partners themselves are invited to reach out through official
            channels.
          </p>
          <h3 className={classes.bannerText1}>How to Apply</h3>
          <p>
            If you’re building something great, we want to hear about it! To
            apply for a grant, please reach out to a team member via <a href="mailto:david@glitter.finance">email</a> or
            any of our official channels with your proposal.
          </p>
          <p>
            As a general guideline, your proposal should outline the benefits
            your project will bring to the ecosystem and emphasise its relevance
            and value to the broader Glitter Finance and Rainbow Network
            communities. The Foundation has a preference for projects nearing
            launch that focus on technology development and projects seeking to
            become ecosystem partners.
          </p>
          <p>
            By prioritising valuable projects nearing launch, the Glitter Fund
            Grant Program will be able to accelerate the growth and expansion of
            the Glitter-Rainbow ecosystem to the benefit of not only those
            involved in the protocols but also the broader web3 community.
          </p>
          <h3 className={classes.bannerText1}>Championing Grant Recipients</h3>
          <p>
            The XGLI DAO will continue to back Glitter Fund grant recipients by
            stewarding XGLI as well as other digital assets available on the
            Glitter Finance platform. Grants will be disbursed through Glitter
            Finance tokenized products, ensuring funding remains in
            line with the platform's core principles. The grant program's
            objectives are threefold:
          </p>
          <ol>
            <li>
              Enhance the utility of Glitter Finance-produced digital assets
            </li>
            <li>Enrich the ecosystem</li>
            <li>
              Promote technology launched on Glitter Finance and Rainbow Network
            </li>
          </ol>
          <p>We will be assessing each application through this lens.</p>
          <p>
            The Glitter Fund Grant Program is designed to nurture innovation and
            development within the Glitter Finance and Rainbow Network
            ecosystems. Through financial support, technical assistance, and
            network and community-building assistance, the Glitter Finance
            Foundation and XGLI DAO aim to create a flourishing, interconnected
            network that benefits everyone. The grant program is set to benefit
            everyone, from our community and partners in web3 to web2 users who
            have yet to explore the decentralised web.
          </p>
          <p>
            If you’re building something amazing, have questions about
            submitting your application, or are ready to apply, 
            <a href="mailto:david@glitter.finance">contact us via email today.</a> 
          </p>
          Our official channels:<br/>
          <a href="https://twitter.com/GlitterFinance">Glitter Finance</a><br/>
          <a href="https://twitter.com/GlitterFund_">Glitter Fund</a><br/>
          <a href="https://twitter.com/dao_xgli">XGLI DAO</a><br/>
          <a href="https://twitter.com/RBWNetwork">Rainbow Network</a>
        </div>
      </Grid>
    </Grid>
  );
}
