import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
        }}
      >
          <Link to="/terms-of-use">
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "4%",
            fontWeight:'bold',
            width: "200px",
            fontFamily: "ProximaNova",
          }}
        >
          Terms Of Use
        </Typography>
        </Link>
        <Link to="/privacy">
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "4%",
            fontWeight:'bold',
            width: "200px",
            fontFamily: "ProximaNova",
          }}
        >
          Privacy Policy
        </Typography>
        </Link>
      </div>
      <Typography
        sx={{
          textAlign: "center",
          marginTop: "2%",
          width: "100%",
          fontFamily: "ProximaNova",
        }}
      >
        ©2023.GLITTER FUND
      </Typography>
    </>
  );
}
