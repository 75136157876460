import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import AllBlogsPage from "./pages/Blog/AllBlogsPage";
import SingleBlogPost from "./pages/Blog/Blog";
import GlitterPrdao from "./pages/Blog/GlitterPrdao";
import UnveilingGitterFund from "./pages/Blog/UnveilingGitterFund";

import Home from "./pages/Home/Home";
import PrivacyPolicy from "./pages/Policy/PrivacyPolicy";
import TermsAndConditions from "./pages/Policy/TermsAndConditions";

export default function Root() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/blog"
          element={
            <Layout>
              <AllBlogsPage />
            </Layout>
          }
        />
        <Route
          path="/introducing-the-glitter-finance-foundation"
          element={
            <Layout>
              <SingleBlogPost />
            </Layout>
          }
        />
        <Route
          path="/glitter-fund-awards-development-grant-to-prdao"
          element={
            <Layout>
              <GlitterPrdao />
            </Layout>
          }
        />
         <Route
          path="/unveiling-the-glitter-fund-grant-program-a-catalyst-for-ecosystem-growth"
          element={
            <Layout>
              <UnveilingGitterFund />
            </Layout>
          }
        />
        <Route
          path="/terms-of-use"
          element={
            <Layout>
              <TermsAndConditions />
            </Layout>
          }
        />
        <Route
          path="/privacy"
          element={
            <Layout>
              <PrivacyPolicy />
            </Layout>
          }
        />
      </Routes>
    </>
  );
}
