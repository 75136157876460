import { Button } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

const PrimaryButton = ({ text, pad, marg, href, type, disabled, onClick }) => {
  const { classes, cx } = useStyles();
  return (
    <Button
      size="small"
      type={type}
      disabled={disabled}
      className={cx(classes.activeBtn)}
      onClick={() => {
        onClick()
      }}
      sx={{
        padding: pad ?? "7px 11%",
        margin: marg ?? 0,
      }}
      href={href?href:''}
    >
      {text}
    </Button>
  );
};

export default PrimaryButton;

const useStyles = makeStyles()((theme) => ({
  activeBtn: {
    fontFamily: "ProximaNova",
    fontSize: "15px",
    color: "#fff",
    fontWeight: "700",
    background: "linear-gradient(180deg, #84F3F4 -1.84%, #65A9DE 101.88%)",
    borderRadius: "50px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: "0 !important",
      fontSize: "10px",
    },
  },
}));
