import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./PolicyStyles";

function TermsAndConditions() {
  const { classes } = styles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid className={classes.homeRootContainer}>
      <Grid className={classes.container}>
        <div>
          <h2> Terms of Use</h2>
          <h3>Latest update: March 2023</h3>
          <p>
            Please read these terms carefully and keep a copy of them for your
            reference. Please also note that there may be specific terms or
            conditions applicable to you as a user in a given jurisdiction, as
            detailed below.
          </p>
          <p>
            Please refer to our Privacy Notice, available at{" "}
            <a href="https://glitterfund.org/privacy">
              https://glitterfund.org/privacy
            </a>{" "}
            for information about how we collect, use, share and otherwise
            process information about you.
          </p>
          <h3>AGREEMENT TO TERMS</h3>
          <p>
            This Terms of Service (“Terms”) is a binding contract between you,
            an individual user or site visitor, whether personally or on behalf
            of an entity (“user,” “you,” “your”) and the Glitter Fund (“Glitter
            Fund,” “we,” “us” or “our”) concerning your access and use of
            Aptos’s services, including the glitterfund.org website as well as
            any other media form, media channel, or mobile website related,
            linked, or otherwise connected thereto (collectively, the
            “Service”). Currently, the Glitter Fund maintains and operates
            glitterfund.org as a portal for news, information, and updates about
            the Glitter Finance, Rainbow Network, the XGLI DAO and any other
            blockchain products of the the Glitter - Rainbow ecosystem, and to
            provide access to certain Glitter - Rainbow services. For the
            avoidance of doubt, Glitter does not control the Glitter Finance
            Platform and cannot control activity and data on the Rainbow
            Network, including without limitation the activities of persons who
            develop and use applications on the Rainbow Network, the validation
            of transactions on the Rainbow Network, or any other use or access
            of the Rainbow Network. The Rainbow Network is an open-source
            protocol that is maintained and processed by Rainbow Network
            validators across the globe.
          </p>
          <p>
            BY ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ,
            UNDERSTOOD, AND AGREE TO BE BOUND BY THE TERMS. IF YOU DO NOT AGREE,
            PLEASE DO NOT USE THE SERVICE.
          </p>
          <p>
            Supplemental terms and conditions or documents that may be posted on
            the Service from time to time are hereby expressly incorporated
            herein by reference. We reserve the right, in our sole discretion,
            to make changes or modifications to these Terms at any time and for
            any reason. We will alert you about any changes by updating the
            “Last updated” date of the Terms, and you waive any right to receive
            specific notice of each such change. It is your responsibility to
            periodically review the Terms to stay informed of updates. You will
            be subject to, and will be deemed to have been made aware of and to
            have accepted, the changes in any revised Terms by your continued
            use of the Service after the date such revised Terms is posted.
          </p>
          <p>
            The information provided on the Service is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Service from other locations do so
            on their own initiative and are solely responsible for compliance
            with local laws, if and to the extent local laws are applicable.
          </p>
          <p>
            The Service is intended for users who are at least 18 years old. You
            agree that by using the Service you are at least 18 years of age (or
            the age of legal majority) or accessing the Service under the
            supervision of a parent or guardian, and you are legally able to
            enter into a contract. If you are a parent or legal guardian of a
            user under the age of 18 (or the age of legal majority), you agree
            to be fully responsible for the acts or omissions of such user in
            relation to the Service. If you use the Service on behalf of another
            person or entity, (a) all references to “you” throughout the Terms
            will include that person or entity, (b) you represent that you are
            authorized to accept these Terms on that person’s or entity’s
            behalf, and (c) in the event you or the person or entity violates
            these Terms, the person or entity agrees to be responsible to us.
          </p>
          <p>
            PLEASE NOTE: THE “DISPUTE RESOLUTION” SECTION OF THESE TERMS
            CONTAINS AN ARBITRATION CLAUSE THAT REQUIRES DISPUTES TO BE
            ARBITRATED ON AN INDIVIDUAL BASIS, AND PROHIBITS CLASS ACTION
            CLAIMS. IT AFFECTS HOW DISPUTES BETWEEN YOU AND APTOS ARE RESOLVED.
            BY ACCEPTING THESE TERMS, YOU AGREE TO BE BOUND BY THIS ARBITRATION
            PROVISION. PLEASE READ IT CAREFULLY.
          </p>
          <h3>3. ACCESS TO THE SERVICE</h3>
          <p>
            Subject to these Terms, we hereby grant you a limited,
            non-exclusive, revocable, non-transferable, non-sublicensable right
            and license to access and use the Service for your personal use
            only, strictly as permitted by the features of the Service. We
            reserve all rights not expressly granted herein.
          </p>
          <h3>TERMINATION OR SUSPENSION OF SERVICE</h3>
          <p>
            WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS, WE RESERVE THE
            RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
            DENY ACCESS TO AND USE OF THE SERVICE (INCLUDING BLOCKING CERTAIN IP
            ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING
            WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
            COVENANT CONTAINED IN THESE TERMS OR OF ANY APPLICABLE LAW OR
            REGULATION. WE MAY TERMINATE YOUR USE OF OR PARTICIPATION IN THE
            SERVICE AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. If we
            terminate or suspend your access to the Service for any reason, you
            are prohibited from attempting to access the Service under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your access, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </p>
          <h3>4. CONTENT THROUGH THE SERVICE</h3>
          <p>
            You are responsible for your use of the Service and for any content
            you provide. You should only provide content that you are
            comfortable sharing with others.
          </p>
          <p>
            Any use or reliance on any content or materials posted via the
            Service or obtained by you through the Service is at your own risk.
            We do not endorse, support, represent or guarantee the completeness,
            truthfulness, accuracy, or reliability of any content or
            communications posted via the Service or endorse any opinions
            expressed via the Service. You understand that by using the Service,
            you may be exposed to content that might be offensive, harmful,
            inaccurate or otherwise inappropriate, or in some cases, postings
            that have been mislabeled or are otherwise deceptive. All content is
            the sole responsibility of the person who originated such content.
            We may not monitor or control the content posted via the Service
            and, we cannot take responsibility for such content. We reserve the
            right to remove content that violates these terms or we otherwise
            deem inappropriate to share on our website, including for example,
            copyright or trademark violations or other intellectual property
            misappropriation, impersonation, unlawful conduct, indecent material
            or harassment. If you believe that your content has been copied in a
            way that constitutes copyright infringement, please report this to
            our designated copyright agent at: Glitter Fund P.O. Box 712, Suite
            102, Cannon Place North Sound Rd., George Town, Grand Cayman, Cayman
            Islands KY1-9006
          </p>
          <p>
            Email:{" "}
            <a href="mailto:business@glitter.finance">
              business@glitter.finance
            </a>
          </p>
          <h3>5. PROHIBITED ACTIVITIES</h3>
          <p>
            {" "}
            You may not access or use the Service for any purpose other than
            that for which we make the Service available. The Service may not be
            used in connection with any commercial endeavors except those that
            are specifically endorsed or approved by us. As a user of the
            Service, you agree not to: Systematically retrieve data or other
            content from the Service to create or compile, directly or
            indirectly, a collection, compilation, database, or directory
            without written permission from us. Make any unauthorized use of the
            Service, including collecting usernames and/or email addresses of
            users by electronic or other means for the purpose of sending
            unsolicited email, or creating user accounts by automated means or
            under false pretenses. Circumvent, disable, or otherwise interfere
            with security-related features of the Service, including features
            that prevent or restrict the use or copying of any content or
            enforce limitations on the use of the Service and/or the content
            contained therein. Engage in unauthorized framing of or linking to
            the Site. Trick, defraud, or mislead us and other users, especially
            in any attempt to learn sensitive account information such as user
            passwords. Make improper use of our support services or submit false
            reports of abuse or misconduct. Engage in any automated use of the
            system, such as using scripts to send comments or messages, or using
            any data mining, robots, or similar data gathering and extraction
            tools. Interfere with, disrupt, or create an undue burden on the
            Site or the networks or services connected to the Site. Attempt to
            impersonate another user or person. Use any information obtained
            from the Site in order to harass, abuse, or harm another person. Use
            the Service as part of any effort to compete with us or otherwise
            use the Service and/or the content for any revenue-generating
            endeavor or commercial enterprise. Decipher, decompile, disassemble,
            or reverse engineer any of the software comprising or in any way
            making up a part of the Site. Harass, annoy, intimidate, or threaten
            any of our employees or agents engaged in providing any portion of
            the Service to you. Attempt to bypass any measures of the Site
            designed to prevent or restrict access to the Site, or any portion
            of the Site. Delete the copyright or other proprietary rights notice
            from any content. Copy or adapt the Site’s software, including but
            not limited to Flash, PHP, HTML, JavaScript, or other code. Upload
            or transmit (or attempt to upload or to transmit) viruses, Trojan
            horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party’s uninterrupted use and enjoyment of the
            Service or modifies, impairs, disrupts, alters, or interferes with
            the use, features, functions, operation, or maintenance of the
            Service. Upload or transmit (or attempt to upload or to transmit)
            any material that acts as a passive or active information collection
            or transmission mechanism, including without limitation, clear
            graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
            cookies, or other similar devices (sometimes referred to as
            “spyware” or “passive collection mechanisms” or “pcms”). Except as
            may be the result of standard search engine or Internet browser
            usage, use, launch, develop, or distribute any automated system,
            including without limitation, any spider, robot, cheat utility,
            scraper, or offline reader that accesses the Site, or using or
            launching any unauthorized script or other software. Disparage,
            tarnish, distribute hate speech/explicit content or otherwise harm,
            in our opinion, us, the Service and/or other users of the Service.
            Copy, reproduce, distribute, publicly perform or publicly display
            all or portions of our Service, except as expressly permitted by us
            or our licensors. Modify our Service, remove any proprietary rights
            notices or markings, or otherwise make any derivative works based
            upon our Service. Infringe any patent, trademark, trade secret,
            copyright or other intellectual or proprietary right of the
            Foundation or any third party. Use the Service in a manner
            inconsistent with any applicable laws or regulations.{" "}
          </p>

          <h3>6. TRADEMARKS</h3>
          <p>
            “Glitter Fund” and our logos, our product or service names, our
            slogans and the look and feel of the Service are trademarks of the
            Foundation and may not be copied, imitated or used, in whole or in
            part, without our prior written permission, which may be obtained by
            emailing{" "}
            <a href="mailto:business@glitter.finance">
              business@glitter.finance
            </a>
            . All other trademarks, registered trademarks, product names and
            company names or logos mentioned on the Service are the property of
            their respective owners. Reference to any products, services,
            processes or other information by trade name, trademark,
            manufacturer, supplier or otherwise does not constitute or imply
            endorsement, sponsorship or recommendation by us.
          </p>

          <h3>7. Governing Law</h3>
          <p>
            Jurisdiction These Terms of Use are governed exclusively by the laws
            of the United Arab Emirates - Dubai. The exclusive jurisdiction is
            Dubai.{" "}
          </p>

          <h3>8. Disclaimer/Limitation of Liability</h3>
          <p>
            The Site and Services are provided on an as-is and as-available
            basis. You agree that your use of the Site and/or Services will be
            at your sole risk except as expressly set out in these Terms and
            Conditions. All warranties, terms, conditions and undertakings,
            express or implied (including by statute, custom or usage, a course
            of dealing, or common law) in connection with the Site and Services
            and your use thereof including, without limitation, the implied
            warranties of satisfactory quality, fitness for a particular purpose
            and non-infringement are excluded to the fullest extent permitted by
            applicable law.
          </p>

          <p>
            We make no warranties or representations about the accuracy or
            completeness of the Site’s content and are not liable for any (1)
            errors or omissions in content; (2) any unauthorized access to or
            use of our servers and/or any and all personal information and/or
            financial information stored on our server; (3) any interruption or
            cessation of transmission to or from the site or services; and/or
            (4) any bugs, viruses, trojan horses, or the like which may be
            transmitted to or through the site by any third party. We will not
            be responsible for any delay or failure to comply with our
            obligations under these Terms and Conditions if such delay or
            failure is caused by an event beyond our reasonable control.{" "}
          </p>

          <p>
            If you are a business user: We will not be liable to you for any
            loss or damage, whether in contract, tort (including negligence),
            breach of statutory duty, or otherwise, even if foreseeable, arising
            under or in connection with: use of, or inability to use, our
            Site/Services; or use of or reliance on any content displayed on our
            Site. In particular, we will not be liable for: loss of profits,
            sales, business, or revenue; business interruption; loss of
            anticipated savings; loss of business opportunity, goodwill or
            reputation; or any indirect or consequential loss or damage. If you
            are a consumer user: Please note that we only provide our Site for
            domestic and private use. You agree not to use our Site for any
            commercial or business purposes, and we have no liability to you for
            any loss of profit, loss of business, business interruption, or loss
            of business opportunity.
          </p>

          <h3>9. Official Communication</h3>
          <p>
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing. You hereby agree
            to the use of electronic signatures, contracts, orders and other
            records and to electronic delivery of notices, policies and records
            of transactions initiated or completed by us or via the Site. You
            hereby waive any rights or requirements under any statutes,
            regulations, rules, ordinances or other laws in any jurisdiction
            which require an original signature or delivery or retention of
            non-electronic records, or to payments or the granting of credits by
            other than electronic means. Links to Third Party Websites or
            Resources. The Interface may allow you to access third-party
            websites or other resources. We provide access only as a convenience
            and are not responsible for the content, products or Interface on or
            available from those resources or links displayed on such websites.
            You acknowledge sole responsibility for and assume all risk arising
            from, your use of any third-party resources.{" "}
          </p>

          <h3>Warranty Disclaimers.</h3>

          <p>
            THE INTERFACE IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND.
            WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT
            OF COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT THE
            INTERFACE WILL MEET YOUR REQUIREMENTS, BE AVAILABLE ON AN
            UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY
            REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS,
            COMPLETENESS OR RELIABILITY OF ANY INFORMATION OR CONTENT ON THE
            INTERFACE. The Glitter Fund FURTHER EXPRESSLY DISCLAIMS ALL
            LIABILITY OR RESPONSIBILITY IN CONNECTION WITH THIRD PARTY SERVICES.
            NOTHING HEREIN NOR ANY USE OF OUR INTERFACE IN CONNECTION WITH THIRD
            PARTY SERVICES CONSTITUTES OUR ENDORSEMENT, RECOMMENDATION OR ANY
            OTHER AFFILIATION OF OR WITH ANY THIRD PARTY SERVICES. The Glitter
            Fund DOES NOT REPRESENT OR WARRANT THAT ANY CONTENT ON THE INTERFACE
            IS ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WE WILL NOT
            BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR TAKEN IN
            RELIANCE ON MATERIAL OR INFORMATION CONTAINED ON THE INTERFACE.
            WHILE Sui Foundation ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE
            INTERFACE AND ANY CONTENT THEREIN SAFE, Sui Foundation CANNOT AND
            DOES NOT REPRESENT OR WARRANT THAT THE INTERFACE, ANY CONTENT
            THEREIN, OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU
            DISCLOSE ONLINE. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING
            INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD
            US RESPONSIBLE FOR ANY BREACH OF SECURITY. The Glitter Fund WILL NOT
            BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKES NO
            RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF
            THE INTERFACE, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR
            CLAIMS ARISING FROM: (I) USER ERROR SUCH AS FORGOTTEN PASSWORDS,
            INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED WALLET ADDRESSES;
            (II) SERVER FAILURE OR DATA LOSS; (III) BLOCKCHAIN NETWORKS,
            CRYPTOCURRENCY WALLETS OR CORRUPT FILES; (IV) UNAUTHORIZED ACCESS TO
            THE INTERFACE; OR (V) ANY THIRD PARTY ACTIVITIES, INCLUDING WITHOUT
            LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS
            OF ATTACK AGAINST ANY BLOCKCHAIN NETWORK UNDERLYING THE INTERFACE.
            THE INTERFACE MAY NOT BE AVAILABLE DUE TO ANY NUMBER OF FACTORS
            INCLUDING, BUT NOT LIMITED TO, PERIODIC SYSTEM MAINTENANCE,
            SCHEDULED OR UNSCHEDULED, ACTS OF GOD, UNAUTHORIZED ACCESS, VIRUSES,
            DENIAL OF SERVICE OR OTHER ATTACKS, TECHNICAL FAILURE OF THE
            INTERFACE AND/OR TELECOMMUNICATIONS INFRASTRUCTURE OR DISRUPTION,
            AND THEREFORE WE EXPRESSLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTY
            REGARDING THE USE AND/OR AVAILABILITY, ACCESSIBILITY, SECURITY OR
            PERFORMANCE OF THE INTERFACE CAUSED BY SUCH FACTORS. WE DO NOT MAKE
            ANY REPRESENTATIONS OR WARRANTIES AGAINST THE POSSIBILITY OF
            DELETION, MISDELIVERY OR FAILURE TO STORE COMMUNICATIONS,
            PERSONALIZED SETTINGS OR OTHER DATA. SOME JURISDICTIONS DO NOT ALLOW
            THE EXCLUSION OF CERTAIN WARRANTIES. ACCORDINGLY, SOME OF THE ABOVE
            DISCLAIMERS OF WARRANTIES MAY NOT APPLY TO YOU.{" "}
          </p>

          <h3>Assumption of Risk. You accept and acknowledge: </h3>
          <p>
            (a) You are solely responsible for determining what, if any, Taxes
            apply to your transactions through the Interface. Neither the
            Glitter Fund nor any Glitter Fund affiliates are responsible for
            determining the Taxes that apply to such transactions.
          </p>
          <p>
            (b) A lack of use or public interest in the creation and development
            of distributed ecosystems could negatively impact the development of
            those ecosystems and related applications, and could therefore also
            negatively impact the potential utility or value of User Content or
            certain digital assets.{" "}
          </p>
          <p>
            (c) By accessing and using the Interface, you represent that you
            understand the inherent risks associated with using cryptographic
            and blockchain-based systems, and that you have a working knowledge
            of the usage and intricacies of tokens such as, bitcoin (BTC), ether
            (ETH), and other digital tokens such as those following the Ethereum
            Token Standard (ERC-20). You further understand that the markets for
            tokens and NFTs can be highly volatile due to factors including (but
            not limited to) adoption, speculation, technology, security, and
            regulation. You acknowledge that the cost and speed of transacting
            with cryptographic and blockchain-based systems are variable and may
            increase at any time. Accordingly, you understand and agree to
            assume full responsibility for all of the risks of accessing and
            using and interacting with the Interface.{" "}
          </p>
          <h3>10. Indemnity.</h3>
          <p>
            {" "}
            You will indemnify, defend (at Glitter Fund’s option) and hold
            Glitter Fund and its officers, directors, employees and agents,
            harmless from and against any claims, disputes, demands,
            liabilities, damages, losses, and costs and expenses, including,
            without limitation, reasonable legal and accounting fees arising out
            of or in any way connected with: (a) your access to or use of the
            Interface, (b) your User Content, or (c) your violation of these
            Terms. You may not settle or otherwise compromise any claim subject
            to this Section without Sui Foundation’s prior written approval.{" "}
          </p>

          <h3>11. Links</h3>
          <p>
            Glitter Fund has not reviewed all of the sites linked to its website
            and is not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by Cardano
            Foundation of the site. Use of any such linked website is at the
            user’s own risk.{" "}
          </p>

          <h3>12. Class Action Waiver.</h3>
          <p>
            {" "}
            YOU AND Glitter Fund AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
            OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
            PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
            PROCEEDING. . Further, if the parties’ Dispute is resolved through
            arbitration, the arbitrator may not consolidate another person’s
            claims with your claims, and may not otherwise preside over any form
            of a representative or class proceeding. If this specific provision
            is found to be unenforceable, then the entirety of this Dispute
            Resolution section shall be null and void.
          </p>
          <p>
            (g) Severability. With the exception of any of the provisions in
            Section 12 of these Terms (“Class Action Waiver”), if an arbitrator
            or court of competent jurisdiction decides that any part of these
            Terms is invalid or unenforceable, the other parts of these Terms
            will still apply.
          </p>
        </div>
      </Grid>
    </Grid>
  );
}

export default TermsAndConditions;
