import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import React from "react";

const GlossyStrip = ({ children }) => {
  const { classes } = useStyles();
  return <Box className={classes.strip}>{children}</Box>;
};

export default GlossyStrip;

const useStyles = makeStyles()(() => ({
  strip: {
    fontFamily: "ProximaNova",
    background: "rgb(255 250 250 / 30%)",
    boxShadow: "0px 4px 18px rgba(165, 109, 244, 0.15)",
    backdropFilter: "blur(12.5px)",
    WebKitBackdropFilter: "blur(12.5px)",
    MozBackdropFilter: "blur(12.5px)",
  },
}));
