import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

import glitterLogo from "../../assets/imgs/glitter-fund-logo.png";
import useStyles from "./HeaderStyles";
import PrimaryButton from "../../comp-reusable/PrimaryButton";
import ComunityButton from "./ComunityButton";
import { useNavigate } from "react-router-dom";

function Header({}) {
  const { classes, cx } = useStyles();
  const smallPoint = useMediaQuery("(max-width: 750px)");
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        className={cx(classes.contentContianer)}
        style={{ justifyContent: "space-between" }}
      >
        <Grid item md={3} sm={3}>
          <Link
            to="/"
            className={classes.logoDiv}
            style={{ textDecoration: "none" }}
          >
            <img
              src={glitterLogo}
              className={classes.logoImg}
              alt="Glitter Fund"
            />
          </Link>
        </Grid>

        <Grid
          item
          md={9}
          sm={9}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            // alignItems: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "start",
              paddingTop: "20px",
            }}
          >
            <ComunityButton pad={smallPoint ? "5px 20px" : "7px 30px"} />
            {!smallPoint && (
              <PrimaryButton
                text="Contact us "
                marg="0px 3px"
                pad="7px 30px"
                href="#contactus"
                onClick={() =>  navigate("/#contactus")}
              />
            )}
            {!smallPoint && (
              <PrimaryButton
                text="Blog"
                marg="0px 3px"
                pad="7px 30px"
                onClick={() => navigate("/blog")}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export { Header };
