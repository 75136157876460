import React from "react";
import { Grid } from "@mui/material";

import leftMiddleBall from "../assets/imgs/left-middle-ball.svg";
import leftBottomBall from "../assets/imgs/left-bottom-ball.svg";
import rightTopBall from "../assets/imgs/right-top-ball.svg";
import rightMiddleBall from "../assets/imgs/right-middle-ball.svg";
import rightBottomBall from "../assets/imgs/right-bottom-ball.svg";
import topStars from "../assets/imgs/top-stars.svg";
import bottomStars from "../assets/imgs/bottom-stars.svg";
import useStyles from "./LayoutStyles";
import { Header } from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const Layout = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.rootContainer}>
      <Grid
        container
        className={classes.ballsLayer}
        style={{
          backgroundImage: `url(${leftMiddleBall}), url(${leftBottomBall})
          , url(${rightTopBall}), url(${rightMiddleBall}), url(${rightBottomBall}), url(${topStars}), url(${bottomStars})
          `,
        }}
      >
        <Header />
        {children}
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Layout;
