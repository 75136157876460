import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import styles from "./BlogStyles";
import BlogHeader from "../../assets/imgs/Grant.jpg";
export default function GlitterPrdao() {
  const { classes, cx } = styles();
  const smallPoint = useMediaQuery("(max-width: 750px)");
  return (
    <Grid className={classes.homeRootContainer}>
      <Grid className={classes.container}>
        <div>
          <img
            src={BlogHeader}
            alt="Introducing the Glitter Finance Foundation"
            className={classes.headerImage}
          />

          {/* <h1 className={classes.bannerText2}>
            Introducing the Glitter Finance Foundation
          </h1> */}
          <p>
            Glitter Fund is proud to announce the first recipient of its grant
            program, PRDAO—a decentralised hub for web3 marketing. This funding
            will allow PRDAO to continue development on the Algorand blockchain
            as they build out their innovative solution.
          </p>
          <p>
            In addition, PRDAO has committed to accepting xSOL tokens as one of
            their payment methods. xSOL is a wrapped token maintained by Glitter
            Finance that allows users to bridge SOL from its native Solana to
            the Algorand ecosystem. Glitter’s xSOL bridge was the first to
            connect Algorand to Solana and serves as an important route for
            cross-chain traffic between the two chains.
          </p>
          <p>
            PRDAO’s xSOL support means that more users can access the platform’s
            freelance community regardless of the network they hold most of
            their assets on. This brings in new users and freelancers, but also
            serves as an example of why cross-chain infrastructure is so
            important.
          </p>

          <p>
            “PRDAO provides a unique solution to a growing challenge,” said
            Glitter Fund Founder David Dobrovitsky. “We’re proud to be backing
            them, just as we’re proud to provide the cross-chain infrastructure
            that enables xSOL on the platform.”
          </p>
          <p>
            The world of web3 marketing is complex, with projects often required
            to navigate between the engaged world of blockchain enthusiasts and
            the uninitiated in the general population. Combine potential global
            reach with varied and often conflicting advertising regulations, and
            you get a situation that inexperienced teams and web2 marketing
            agencies struggle to overcome.
          </p>
          <p>
            PRDAO uses a Decentralised Autonomous Organisation (DAO) to provide
            a uniquely web3 solution to this challenge. Projects and marketing
            experts are automatically connected on jobs and tasks, while other
            members are rewarded for validating the quality of work from their
            peers.
          </p>
          <p>
            According to Ryan Shriver, the CEO and Founder of PRDAO, the
            platform “addresses the problems that both freelancers and their
            clients face in an increasingly decentralised hiring market”. In
            particular, peer validation and transparent bidding ensure that both
            client and freelancer cooperate at a fair price and that work
            standards match the terms and agreed prices. Decentralised quality
            reviews and dispute resolution means that cases are solved quickly
            and efficiently, ensuring better outcomes for all parties.
          </p>
          <p>
            In addition to xSOL, PRDAO will accept ETH and ALGO in a commitment
            to multi-chain support. As a prominent supporter of cross-chain
            innovation, the Glitter Fund is excited to back the next generation
            of web3 solutions.
          </p>
          <p>
            If you’re a project with a cross-chain vision, get in contact with
            the Glitter Fund today.{" "}
          </p>

          <p>
            To learn more about the PRDAO community, join their{" "}
            <a href="https://discord.com/invite/uE52jPKuje">
              Discord community
            </a>{" "}
            and get updates from their{" "}
            <a href="https://www.prdao.io">website</a> and
            <a href="https://twitter.com/PRDAOOfficial"> Twitter.</a>
          </p>
        </div>
      </Grid>
    </Grid>
  );
}
