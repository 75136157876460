import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import styles from "./BlogStyles";
import BlogHeader from '../../assets/imgs/blog-header.png'
export default function SingleBlogPost() {
  const { classes, cx } = styles();
  const smallPoint = useMediaQuery("(max-width: 750px)");
  return (
    <Grid className={classes.homeRootContainer}>
      <Grid className={classes.container}>
        <div>
        <img src={BlogHeader} alt="Introducing the Glitter Finance Foundation" className={classes.headerImage}/>

          {/* <h1 className={classes.bannerText2}>
            Introducing the Glitter Finance Foundation
          </h1> */}
          <p>
            Today we’re excited to announce the launch of the Glitter Finance
            Foundation: a body created to steward the development of the Glitter
            Finance and Rainbow Network ecosystems.
          </p>
          <p>
            The Foundation is an important milestone on the path towards full
            decentralisation. It moves the Glitter and Rainbow protocols away
            from a traditional top-down company structure towards one that is
            governed by stakeholders.
          </p>
          <h2 className={classes.bannerText1}>The Foundation’s focus</h2>
          <p>
            The Glitter Finance Foundation will comprise members from Glitter’s
            core team and other expert advisors, providing critical oversight in
            the following three areas:
          </p>
          <ol>
            <li>XGLI DAO coordination</li>
            <li>Grant programs</li>
            <li>Developer relations program</li>
          </ol>
          <h3 className={classes.bannerText1}>XGLI DAO coordination</h3>
          <p>
            In March we announced the XGLI DAO: a decentralised autonomous
            organisation that gives stakeholders control over the future of the
            Glitter and Rainbow ecosystems. Membership and voting rights are
            awarded to XGLI token holders who will consider proposals relating
            to protocol development, token listings, rewards and more.
          </p>
          <p>
            To learn more about the XGLI DAO, check out our recent announcement.
          </p>
          <p>
            The Glitter Finance Foundation will act as an intermediary between
            the DAO and the protocols themselves. This means implementing the
            decisions made by the DAO on a protocol level and ensuring that the
            Glitter and Rainbow ecosystems develop in the direction agreed upon
            by stakeholders.
          </p>
          <h3 className={classes.bannerText1}>Grant programs</h3>
          <p>
            Glitter Finance is more than a bridge: it’s a comprehensive
            cross-chain ecosystem that provides easy access to liquidity,
            regardless of the chain it's on. Rainbow Network, as an extension of
            this principle, expands the concept of unified liquidity across an
            entire blockchain.
          </p>
          <p>
            To help encourage developers to build using Glitter and Rainbow
            infrastructure, the Glitter Finance Foundation will create a grants
            program to bring innovation into the ecosystem. The Foundation will
            consider applications and provide funding after further consultation
            with the XGLI DAO, ensuring that the entire network grows and
            benefits from these investments.
          </p>
          <h3 className={classes.bannerText1}>Developer relations program</h3>
          <p>
            Developers are the lifeblood of any ecosystem, bringing innovation
            and users to a protocol via the apps they create. To help encourage
            more developer interest in Glitter and Rainbow, the Glitter Finance
            Foundation will run a developer relations program aimed at educating
            the community about what the protocols have to offer.
          </p>
          <p>
            The program itself will target several areas, including
            sponsorships, hackathons, and other incentives to encourage builders
            to explore the benefits of building with Glitter and Rainbow.
          </p>
          <h2 className={classes.bannerText1}>A robust, decentralised future</h2>
          <p>
            The Glitter Finance Foundation's launch is a significant milestone
            in the development of our decentralised ecosystems. The Foundation's
            coordination with the XGLI DAO empowers stakeholders to shape the
            future of the Glitter and Rainbow ecosystems. By acting as an
            intermediary between the DAO and the protocols, the Foundation
            streamlines decision-making and ensures ecosystem development aligns
            with stakeholder goals.
          </p>
          <p>
            The grants program incentivises innovation, while the developer
            relations program fosters growth by educating the community about
            the ecosystem's benefits. Through these initiatives, The Foundation
            aims to provide easy access to liquidity and expand the idea of
            unified liquidity to the benefit of the entire blockchain industry.
          </p>
        </div>
      </Grid>
    </Grid>
  );
}
