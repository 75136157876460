import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IntroGlitterFund from "../../assets/imgs/blog-header.png";
import GlitterPrdao from "../../assets/imgs/Grant.jpg";
import UnveilingGlitterFund from "../../assets/imgs/grantprogram.jpg";


import { useNavigate } from "react-router-dom";

export default function AllBlogsPage() {
  let navigate = useNavigate();

  const cards = [
    {
      title: "Unveiling the Glitter Fund Grant Program",
      desc: "Glitter Fund Grant Program, an initiative to expedite innovation and growth within the Glitter Finance and Rainbow Network ecosystems.",
      image: UnveilingGlitterFund,
      link: "/unveiling-the-glitter-fund-grant-program-a-catalyst-for-ecosystem-growth",
    },
    {
      title: "Introducing the Glitter Finance Foundation",
      desc: "Glitter Finance Foundation: a body created to steward the development of the Glitter Finance and Rainbow Network ecosystems.",
      image: IntroGlitterFund,
      link: "/introducing-the-glitter-finance-foundation",
    },
    {
      title: "Glitter Fund Awards Development Grant to PRDAO",
      desc: "Glitter Fund is proud to announce the first recipient of its grant program, PRDAO—a decentralised hub for web3 marketing.",
      image: GlitterPrdao,
      link: "/glitter-fund-awards-development-grant-to-prdao",
    }
  ];
  
  return (
    <Container sx={{ py: 8 }} maxWidth="md">
      {/* End hero unit */}
      <Grid container spacing={4}>
        {cards.map((card) => (
          <Grid item key={card} xs={12} sm={6} md={6}>
            <Card sx={{ display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                sx={
                  {
                    // 16:9
                    // pt: '56.25%',
                  }
                }
                image={card?.image}
                alt="random"
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {card?.title}
                </Typography>
                <Typography>{card?.desc}</Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => navigate(card?.link)}>
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
