import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme) => ({
  container: {
    padding: "0% 8%",
  },
  banner: {
    height: "70vh",
    paddingTop: "200px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1%",
      height: "80vh",
    },
  },
  bannerText: {
    fontFamily: "ProximaNova",
    fontWeight: 700,
    fontSize: "60px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "ProximaNova",
      paddingTop: "30%",
      fontSize: "50px",
    },
  },
  firstLineText: {
    margin: "0px",
    padding: "0px",
    lineHeight: "1",
  },
  bannerText1: {
    color: "var(--clr-pink)",
  },
  bannerText2: {
    color: "var(--clr-purple)",
  },
  bannerText3: {
    color: "var(--clr-deep-blue)",
    fontSize: "35px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },

  aboutText: {
    fontFamily: "ProximaNova",
    textAlign: "center",
    color: "#3B205C",
    fontWeight: 400,
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      // paddingTop: "10%",
    },
  },
  sectionHeading: {
    fontFamily: "ProximaNova",
    marginTop: "10%",
    marginBottom: "2%",
    textAlign: "center",
    color: "var(--clr-deep-blue)",
    fontSize: "50px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2%",

      fontSize: "20px",
      paddingBottom: "10%",
    },
  },
  boldnames: {
    fontWeight: "bold",
    color: "var(--clr-pink)",
  },
  contactContainer: {
    fontFamily: "ProximaNova",
    padding: "0% 10%",
  },
  glossyPanel: {
    fontFamily: "ProximaNova",
    padding: "3% 8%",
    borderRadius: "40px",
    background: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0px 4px 18px rgba(165, 109, 244, 0.15)",
    backdropFilter: "blur(12.5px)",
    WebKitBackdropFilter: "blur(12.5px)",
    MozBackdropFilter: "blur(12.5px)",
    [theme.breakpoints.down("sm")]: {
      padding: "10% 8%",
    },
  },
  partnersSection: {
    fontFamily: "ProximaNova",
    padding: "5% 10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  partnersLogos: {
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
      height: "20px",
    },
  },
}));

export default styles;
