import { Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";

import PrimaryButton from "../../../comp-reusable/PrimaryButton";
import useStyles from "../HomeStyles";

const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAIL_SERVICE_ID;
const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAIL_PUBLIC_KEY;

export default function ContactUs() {
  const { classes } = useStyles();
  const formref = useRef(null);
  const [formstate, setFormstate] = useState({
    name: "",
    company: "",
    email: "",
    website: "",
    twitter: "",
    telegram: "",
    message: "",
    isSubmited: false,
  });

  const fields = [
    {
      name: "name",
      value: "",
      label: "Name",
      required: true,
    },
    {
      name: "company",
      value: "",
      label: "Company",
      required: true,
    },

    {
      name: "email",
      value: "",
      label: "Email",
      required: true,
    },
    {
      name: "website",
      value: "",
      label: "Website",
      required: true,
    },
    {
      name: "twitter",
      value: "",
      label: "Twitter",
      required: true,
    },
    {
      name: "telegram",
      value: "",
      label: "Telegram",
      required: true,
    },
  ];

  const TextFieldInput = ({
    value,
    name,
    onchange,
    onblur,
    label,
    errors,
    touched,
  }) => {
    return (
      <>
        <TextField
          style={{ width: "100%" }}
          variant="standard"
          name={name}
          type={"text"}
          value={value}
          onBlur={onblur}
          onChange={onchange}
          required={true}
          label={label}
          autoComplete="off"
        />
        {touched[name] && errors[name] && (
          <p
            style={{
              color: "#DF4646",
            }}
          >
            {errors[name]}
          </p>
        )}
      </>
    );
  };

  const ContactUsValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    company: Yup.string().required("Company is required"),
    email: Yup.string().required("Email is required"),
    website: Yup.string().required("Website is required"),
    twitter: Yup.string().required("Twitter is required"),
    telegram: Yup.string().required("Telegram is required"),
  });

  return (
    <Grid className={classes.contactContainer}>
      <Grid className={classes.glossyPanel}>
        <Formik
          initialValues={{
            name: "",
            company: "",
            email: "",
            website: "",
            twitter: "",
            telegram: "",
            message: "",
          }}
          validationSchema={ContactUsValidationSchema}
          onSubmit={(values, { resetForm }) => {
            setFormstate({
              name: "",
              company: "",
              email: "",
              website: "",
              twitter: "",
              telegram: "",
              message: "",
              isSubmited: true,
            });

            emailjs
              .sendForm(
                EMAIL_SERVICE_ID,
                EMAIL_TEMPLATE_ID,
                formref.current,
                EMAIL_PUBLIC_KEY
              )
              .then(
                (result) => {
                  setTimeout(() => {
                    setFormstate({
                      ...formstate,
                      isSubmited: false,
                    });
                  }, 3000);
                  resetForm();
                },
                (error) => {
                  console.log(error.text);
                }
              );
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            errors,
            touched,
          }) => {
            return (
              <form onSubmit={handleSubmit} ref={formref}>
                <Grid container spacing={3}>
                  {fields.map((x, i) => (
                    <Grid item lg={12 / 2} md={12 / 2} sm={12} xs={12} key={i}>
                      <TextFieldInput
                        name={x.name}
                        label={x.label}
                        value={values[x.name]}
                        onchange={handleChange}
                        onblur={handleBlur}
                        required={true}
                        errors={errors}
                        touched={touched}
                      />
                    </Grid>
                  ))}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      variant="standard"
                      name={"message"}
                      multiline
                      rows={10}
                      onBlur={handleBlur}
                      value={values["message"]}
                      onChange={handleChange}
                      label={"Type your message"}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {!formstate.isSubmited ? (
                    <PrimaryButton
                      type="submit"
                      disabled={isSubmitting}
                      text="Submit"
                      marg="10px 3px"
                      onClick={() => {}}
                    />
                  ) : (
                    <Typography className={classes.aboutText} variant="h5">
                      Your request has been sent.
                    </Typography>
                  )}
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
